import "./navbar.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import logo from "../../assets/NEW_logo.png";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";
import Searchbar from "../search/search";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Services from "../../config/services/authServices";
import CategoriesList from "./CategoriesList";
import { Buffer } from "buffer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { isMobile } from "react-device-detect";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import { axiosInstace } from "../../serviec/axiosConfig";
import { STUDIO_BASE_URL } from "../../config/constants";

export default function Navbar() {
  const [profilePic, setProfilePic] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  // const cookieOpen = cookies.get('openedx-language-preference')

  const [session, setSession] = useState(
    JSON.parse(localStorage.getItem("user_stu") || "{}")
  );
  const navigateHome = () => navigate("/");
  const navigatesignin = () =>
    navigate(`/signin?redirect=${location.pathname}`);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = async () => {
    handleClose();
    localStorage.removeItem("user_stu");
    await Services.logout();
    navigate("/");
  };
  const [token, setToken] = useState();
  useEffect(() => {
    if (token?.length > 10) {
      localStorage.setItem("user_stu", token);
      setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
    }
  }, [token]);

  useEffect(() => {
    setToken(
      Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString(
        "utf-8"
      )
    );
    setSession(JSON.parse(localStorage.getItem("user_stu") || "{}"));
  }, [location, URLSearchParams]);

  useEffect(() => {
    axiosInstace
      .get(
        `/api/user/v1/accounts/${session.username}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${session.access_token}`,
          },
        }
      )
      .then((res) => {
        setProfilePic(res.data.profile_image.image_url_full);
      })
      .catch((err) => console.log({ err }));
  }, []);
  //   axios
  //     .post(`https://erp.eyouthlearning.com/api/method/login`, {
  //       usr: "ahmed.moustafa@eyouthegypt.com",
  //       pwd: "12345678",
  //     },{
  //       withCredentials: true
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => console.log({ err }));
  // }, []);

  return (
    <>
      <div className="bg-light nav--bar">
        {/* <div className="beta-version-header">
          <p className="beta-version-animation">Beta Version</p>
        </div> */}
        <div className="d-flex justify-content-evenly align-items-center w-100 border-bottom">
          <div className="col-3">
            <img
              alt="eyouth-logo"
              loading="lazy"
              src={logo}
              className="logo--image"
              onClick={navigateHome}
            />
          </div>

          <Searchbar classes="col-5" />

          <div className="col-4 d-flex justify-content-center align-items-center my-0">
            {Object.keys(session).length === 0 &&
              pathname !== "/signin" &&
              pathname !== "/signup" &&
              pathname !== "/studio" && (
                <button
                  className="btn btn-primary mx-1 w-50 btn--signin "
                  onClick={navigatesignin}
                >
                  {" "}
                  {t("Sign In")}{" "}
                </button>
              )}
            {/* dropdown mune*/}
            {Object.keys(session).length > 0 && pathname !== "/studio" && (
              <div>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="profile-btn bg-light border-button rounded p-1"
                >
                  {/* <PersonIcon className="profile-btn" /> */}

                  {!isMobile ? (
                    <>
                      <p className="username-button mx-1">
                        {session?.username}
                      </p>
                      {/* <img
                        width="22px"
                        className=""
                        style={{ borderRadius: "50%" }}
                        src={profilePic ? profilePic : profileImage}
                        alt={session.username}
                      /> */}
                    </>
                  ) : (
                    // <img
                    //   width="22px"
                    //   className="border"
                    //   style={{ borderRadius: "50%" }}
                    //   src={localStorage.getItem("profilePic")}
                    //   alt={session.username}
                    // />
                    <AccountCircleIcon className="account-circle fs-6" />
                  )}
                  {/* {isMobile && (
                    
                  )} */}
                  <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  style={{ zIndex: "999999" }}
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {/* <div className=" d-flex justify-content-center align-items-center flex-column  w-100"> */}

                  {/* <h5 className="text-center mt-4">{session?.username}</h5> */}
                  {/* <h5 className='text-center mt-4'>{decoded?.email}</h5> */}
                  {/* </div> */}
                  <MenuItem onClick={handleClose} className="fs-5">
                    <Link to="/Mycourses"> دوراتي التدريبية</Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className="">
                    <h5
                      onClick={() =>
                        (window.location.href = `https://apps.experience.eyouthlearning.com/profile/u/${session?.username}`)
                      }
                    >
                      الصفحة الشخصية
                    </h5>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className="fs-5">
                    <a href="https://apps.experience.eyouthlearning.com/account">
                      {" "}
                      اعدادات الحساب{" "}
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className="fs-5">
                    <a href="https://apps.experience.eyouthlearning.com/orders/orders">
                      {" "}
                      سجل الطلبات
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>
                    <button className="btn btn-light border m-auto">
                      {" "}
                      {t("Sign Out")}
                    </button>
                  </MenuItem>
                </Menu>
                <a
                  href="https://apps.experience.eyouthlearning.com/payment"
                  className="mx-1"
                >
                  <strong>
                    <ShoppingCartIcon className="shoppingCart" />
                  </strong>
                </a>
              </div>
            )}
            {pathname === "/studio" && (
              <button
                className={`btn btn-primary  mx-1 btn--signin d-flex justify-content-around align-items-center ${isMobile ? "py-3" : "py2"
                  }`}
                onClick={() => {
                  if (Object.keys(session).length > 0)
                    window.location.href =
                      `${STUDIO_BASE_URL}/home/`;
                  else
                    navigate(
                      `/signin?redirect=${STUDIO_BASE_URL}/home/`
                    );
                }}
              >
                <LoginIcon className={`mx-1 ${isMobile ? "fs-5" : "fs-3 "} `} />
                <span>ستوديو</span>
              </button>
            )}
            {/* <div className='mt-4 me-5' title='language'><LanguageIcon className='language-button' onClick={handleClicklanguage} /></div> */}
          </div>
        </div>

        <div>
          <ul
            className={`d-flex align-items-center ${isMobile ? "justify-content-evenly" : ""
              } `}
          >
            <li>
              <div className="navigation">
                <a
                  className="nav-link hover-underline-animation mx-1"
                  href="/all-courses?pacing=true"
                >
                  {t("Interactive Recorded programs")}
                  <KeyboardArrowDownIcon className="icon-none-sm keyboardArrow mx-1" />
                </a>
                <div className="navigation-content">
                  <div className="d-flex justify-content-around row px-4">
                    {<CategoriesList />}
                  </div>
                </div>
              </div>
            </li>
            <li className={!isMobile ? "mx-5" : ""}>
              <div className="navigation">
                <a
                  className="nav-link hover-underline-animation mx-1"
                  href="/all-courses?pacing=false"
                >
                  {t("LIVE Programs")}
                  <KeyboardArrowDownIcon className="icon-none-sm mx-1 keyboardArrow" />
                </a>
                {<CategoriesList isLive />}
              </div>
            </li>
            <li>
              <div className="navigation">
                <a
                  className="nav-link hover-underline-animation mx-1"
                  href="/all-courses?page=1"
                >
                  {t("Discover")}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export const PartnersList = [
  "90516639_115107153445221_2698623042652733440_n.webp",
  "IMG-4337.PNG",
  "IMG-4342.JPG",
  "IMG-4349.PNG",
  "IMG-8379.PNG",
  "Ministry_of_Education_(Egypt)_logo_(wikiar).png",
  "new-logo_web.png",
  "وزارة-الصحةjpg.webp",
  "155881549716117.png",
  "1673276248585.jpg",
  "GESR-Logo-Colors.png",
  "IMG-4320.PNG",
  "IMG-4321.JPG",
  "IMG-4322.PNG",
  "IMG-4323.JPG",
  "IMG-4324.PNG",
  "IMG-4325.PNG",
  "IMG-4326.GIF",
  "IMG-4327.JPG",
  "IMG-4328.PNG",
  "IMG-4329.JPG",
  "IMG-4330.PNG",
  "IMG-4331.JPG",
  "IMG-4332.PNG",
  "IMG-4333.PNG",
  "IMG-4334.PNG",
  "IMG-4335.JPG",
  "IMG-4336.PNG",
  "IMG-4338.JPG",
  "IMG-4339.PNG",
  "IMG-4340.PNG",
  "IMG-4341.PNG",
  "IMG-4343.JPG",
  "IMG-4344.JPG",
  "IMG-4345.PNG",
  "IMG-4346.PNG",
  "IMG-4347.PNG",
  "IMG-4348.PNG",
  "IMG-4350.PNG",
  "IMG-4351.PNG",
  "IMG-4352.PNG",
  "IMG-4353.PNG",
  "IMG-4354.JPG",
  "IMG-4355.PNG",
  "IMG-4356.PNG",
  "IMG-4357.JPG",
  "IMG-8358.JPG",
  "IMG-8359.PNG",
  "IMG-8360.JPG",
  "IMG-8361.JPG",
  "IMG-8362.PNG",
  "IMG-8363.PNG",
  "IMG-8364.PNG",
  "IMG-8365.PNG",
  "IMG-8366.JPG",
  "12249431486_64af0ce01a_b.jpg",
  "IMG-8367.PNG",
  "IMG-8368.PNG",
  "IMG-8369.PNG",
  "IMG-8370.JPG",
  "IMG-8371.PNG",
  "IMG-8372.JPG",
  "IMG-8373.PNG",
  "IMG-8374.PNG",
  "IMG-8375.PNG",
  "IMG-8376.PNG",
  "IMG-8377.PNG",
  "IMG-8378.PNG",
  "IMG-8380.JPG",
  "IMG-8381.PNG",
  "IMG-8382.JPG",
  "IMG-8383.PNG",
  "IMG-8384.JPG",
  "IMG-8385.JPG",
  "IMG-8386.PNG",
  "IMG-8387.PNG",
  "IMG-8388.JPG",
  "IMG-8389.JPG",
  "IMG-8390.JPG",
  "IMG-8391.PNG",
  "IMG-8392.JPG",
  "IMG-8393.JPG",
  "IMG-8394.JPG",
  "IOM_Logo (1).png",
  "L_Oréal-Egypt-36052-1539523158-og (1).webp",
  "Plan_International_Logo_blue.jpg",
  "UN-Women-logo-social-media-1024x512-en.png",
  "Vodafone_2017_logo.svg.png",
  "Voluntary_Service_Overseas_(VSO)_logo.png",
  "download (10).png",
  "download (7).jpg",
  "download (7).png",
  "download (8).jpg",
  "download (8).png",
  "download (9).jpg",
  "download (9).png",
  "maxresdefault.jpg",
  "wfp-logo-standard-blue-en.png"
]

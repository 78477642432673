import Http from "../network/http";
import { APIExperience, ECOMMERCE_URL, STUDIO_BASE_URL } from "../constants";
import moment from "moment";
import axios from "axios";

const formData = new FormData();
function handleClicklanguage(username) {
  // i18n.changeLanguage(!isArabicLang ? "ar" : "en");
  // cookies.set(
  //   "openedx-language-preference",
  //   (document.documentElement.lang = isArabicLang ? "ar" : "en")
  // );
  var axios = require("axios");
  var data = JSON.stringify({
    "pref-lang": "ar",
  });

  var config = {
    method: "patch",
    withCredentials: true,
    url: `${APIExperience}/accounts/user_setlang/${username}`,
    headers: {
      "Accept-Language": "en-US,en;q=0.9,ar;q=0.8",
      Connection: "keep-alive",
      "Content-Type": "application/json",
      "User-Agent":
        "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
    },
    data: data,
  };

  axios(config)
    .then((res) => {
      // console.log("res :>> ", res.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}
const Services = {
 
  login: async (credentials) =>
    await Http.POST(`${APIExperience}/oauth2/access_token/`, credentials, true)
      .then(async (res) => {
        const login_status = await Http.POST(
          `${APIExperience}/oauth2/login/`,
          credentials,
          {
            withCredentials: true,
            headers: { Authorization: `bearer ${res?.data?.access_token}` },
          }
        )
          .then((res) => res)
          .catch((err) => {
            return err;
          });
        if (login_status?.response?.status === 401)
          throw new Error("Please Confirm your email and try again");
        const username = await axios({
          url: `${APIExperience}/api/user/v1/me`,
          headers: { Authorization: `bearer ${res?.data?.access_token}` },
        }).then((res) => res.data.username);
        localStorage.setItem(
          "user_stu",
          JSON.stringify({
            ...res.data,
            username,
            expiration: moment().add(32400, "seconds").unix(),
            // 32400
            // res.data.expires_in
          })
        );
        handleClicklanguage(username);

        // console.log("login", res.data);
        return res.data;
      })
      .catch((err) => err.response),

  publicLogin: async (credentials) => {
    return await Http.POST(
      `${APIExperience}/oauth2/access_token/`,
      credentials,
      true
    ).then((res) => {
      localStorage.setItem("userPublic_stu", JSON.stringify(res.data));
      return res.data;
    });
  },
  register: async (credentials) =>
    await Http.POST(`${APIExperience}/api/user/v1/account/registration/`, credentials)
      .then((res) => res.data)
      .catch((err) => err.response),

  logout: async () => {
    try {
      await axios({
        method: "POST",
        url: `${APIExperience}/logout`,
        withCredentials: true,
      });
    } catch {}
    window.location.href = `${ECOMMERCE_URL}/logout`;
  },
};

export default Services;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import "./search.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import { axiosInstace } from "../../serviec/axiosConfig";

export default function Searchbar(props) {
  const [searchbar, setSearchbar] = useState([{ name: "....." }]);
  const [selected, setSelected] = useState("");

  const something = (event) => {
    if (event.keyCode === "Enter") {
      return (
        <Link
          to={`/details/${searchbar.find((itemFound) => itemFound.name === selected)?.id
            }`}
        ></Link>
      );
    }
  };

  useEffect(() => {
    axiosInstace
      .get(
        `/api/courses/v1/courses/search`
      )
      .then((res) => {
        setSearchbar(res.data);

      })
      .catch((err) => console.log(err));
  }, []);

  //  console.log(searchbar)
  return (
    <Stack className={props.classes} spacing={2}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        value={selected || ""}
        // onKeyDown={(e) => something(e)}
        onChange={(e) => {
          setSelected(e.target.textContent);
        }}
        // onRequestSearch={searchbar.find(
        //   (window.location.href = "http://www.google.com")
        // )}
        options={searchbar.map((option) => option.name)}
        renderInput={(params) => (
          <>
            <TextField
              placeholder="ابحث عن اي دورة تدريبية"
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={
                        !searchbar.find(
                          (itemFound) => itemFound.name === selected
                        )?.course_id
                      }
                    >
                      <a
                        href={`/details/${searchbar.find(
                          (itemFound) => itemFound.name === selected
                        )?.course_id
                          }`}
                      >
                        {" "}


                        <SearchIcon />
                      </a>
                    </IconButton>

                  </InputAdornment>
                ),
              }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  const foundCourseId = searchbar.find((itemFound) => itemFound.name === event.target.value)?.course_id;
                  if (foundCourseId) {
                    window.location.assign(`/details/${foundCourseId}`);
                  } else {
                    event.target.blur();
                  }
                }
              }}
            />
          </>
        )}
      />
    </Stack>
  );
}

export const HOST = window.location.origin;
export const APIExperience = "https://empower.eyouthlearning.com";
export const STUDIO_BASE_URL = "https://studio.empower.eyouthlearning.com";
export const ECOMMERCE_URL = "https://ecommerce.empower.eyouthlearning.com";
export const APIForms = "https://forms.eyouthlearning.com";
export const PROFILE_IMAGES_PREFIX = "https://files.empower.eyouthlearning.com/openedx/openedx"
export const CATEGORIES_LIST = [
    "Banking",
    "Business_and_Management",
    "Technology_Science",
    "IT_and_Software",
    "Sales",
    "Languages",
    "Soft_Skills",
    "Media_Photography_and_Film",
    "Training_and_Development",
    "Career_Development",
    "Programming_and_Technology",
    "Designing",
    "HR",
    "Freelancing",
    "Entrepreneurship",
    "Marketing",
    "Operations",
    "Tourism",
    "PR",
    "Science",
    "Oil_and_Gas",
    "Education",
    "Humanitarian_Work",
    "Football_Commentaries",
    "Teaching_and_Academics",
    "Pharmacy"
]

const CONSTANTS = {
    HOST,
    STUDIO_BASE_URL,
    APIExperience,
    APIForms,
    PROFILE_IMAGES_PREFIX,
    CATEGORIES_LIST
}

export default CONSTANTS
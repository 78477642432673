import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import './App.css';
import { Buffer } from 'buffer';
import TagManager from 'react-gtm-module'
import { login, publicLogin } from "./config/state/actions";
import { useDispatch } from "react-redux"
import Navbar from './components/navbar/navbar';
import Partners from "./pages/Partners/Partners";
import { APIExperience, STUDIO_BASE_URL } from "./config/constants";

const Footer = React.lazy(() => import('./components/footer/footer'));
const CourseDetails = React.lazy(() => import('./pages/courseDetails/CourseDetails'));
const Home = React.lazy(() => import('./pages/home/home'));
const CourseReviews = React.lazy(() => import('./pages/coursereviwes/coursereviwes'));
const B2B = React.lazy(() => import('./pages/B2B/B2B'));
const Studio = React.lazy(() => import('./pages/Studio/Studio'));
const Signup = React.lazy(() => import('./pages/signup/signup'));
const Signin = React.lazy(() => import('./pages/signin/signin'));
const Coursedegree = React.lazy(() => import('./pages/coursedegree/coursedegree'));
const Thankyou = React.lazy(() => import('./pages/thankyou/thankyou'));
const Mycourses = React.lazy(() => import('./pages/myCourses/mycourses'));
const Instructor = React.lazy(() => import("./pages/instructor/Instructor"));
const Instructors = React.lazy(() => import('./pages/instructors/Instructors'));
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const TermsAndCond = React.lazy(() => import('./pages/TermsAndCond/TermsAndCond'));
const PolicyAndPrivecy = React.lazy(() => import('./pages/PolicyAndPrivecy/PolicyAndPrivecy'));
const AddReviews = React.lazy(() => import('./pages/AddReview/AddReview'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const FilterWithAPIs = React.lazy(() => import('./components/FilterWithAPI/FilterWithAPI'));
const Loading = React.lazy(() => import('./pages/loading/loading'));
// const LastFilter = React.lazy(() => import('./components/current-filter/current-filter'));
const NotFoundPage = React.lazy(() => import('./pages/Not-Found/Not-Found'));

// import addReview from "./pages/addReview/addReview";

// import Coupon from "./pages/Coupon/Coupon";

// 

function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const [token, setToken] = useState()
  const dispatch = useDispatch();


  // if session end sign out 
  const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
  useEffect(() => {
    if (session.expiration && session.expiration < new Date().getTime() / 1000) {
      navigate('/logout')
      localStorage.removeItem("user_stu")
    }
  }, [new Date().getTime()])


  useEffect(() => {
    // console.log('location :>> ', location);
    const tagManagerArgs = {
      gtmId: 'GTM-W9JK3VT'
    }

    TagManager.initialize(tagManagerArgs)
  }, [location])

  useEffect(() => {
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("client_id", "6s7YDbukhIBWjF0iQGWdOIkYQox6bK5LnLp8koLZ");
    formData.append("token_type", 'jwt');
    formData.append("client_secret", "u6xJajLDhK93hXc0nE1tLvyTrhXV6c48byfpBFH9yi2OyXeG7D8J02Fn2WZvkFMFDUQZvVwj7EK2Fp5T4B9FNX7KJUfoctUZapq8ZDtQWa7UgUu3WGgX9npTo8aVoZNJ");
    dispatch(publicLogin(formData))

  }, [])

  useEffect(() => {
    if (token?.length > 10)
      localStorage.setItem("user_stu", token);
  }, [token])

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase().includes("Studio")) {
      window.location.href = `${APIExperience}/login?next=/oauth2/authorize%3Fclient_id%3Dcms-sso%26redirect_uri%3Dhttps%253A%252F%252F${STUDIO_BASE_URL.slice(8, STUDIO_BASE_URL.length)}%252Fcomplete%252Fedx-oauth2%252F%253Fredirect_state%253Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26state%3Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26response_type%3Dcode%26scope%3Duser_id%2Bprofile%2Bemail`
    }
    setToken(Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString("utf-8"))
  }, [location, URLSearchParams])

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
    if (session.expiration < new Date().getTime() / 1000) {
      const formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", "login-service-client-id");
      formData.append("refresh_token", session.refresh_token);
      dispatch(login(formData))
    }
  }, [])


  return (
    <>
      <div className="container-fluid m-0 p-0">
        {location.pathname !== '/loading' && <Navbar token={token} />}
        {/* <SimpleSnackbar /> */}
        <ScrollToTop />
        <div style={{ minHeight: "50vh", marginBottom: ".6rem" }}>
          <Routes>
            <Route path="/" element={<Home />}> </Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/signin" element={<Signin />}></Route>
            <Route path="/home" element={<Home />}> </Route>
            {/* <Route path="/courses-page" element={<LastFilter />}></Route> */}
            {/* <Route path="/CoursesPage"  element={<CoursesPage />}> </Route> */}
            <Route path="/all-courses" element={<FilterWithAPIs />}> </Route>
            <Route path="/thankyou" element={<Thankyou />}> </Route>
            <Route path="/Mycourses" element={<Mycourses />}> </Route>
            <Route path="/instructors" element={<Instructors />}> </Route>
            <Route path="/CourseReviews/:id" element={<CourseReviews />}> </Route>
            <Route path="/instructor/:id" element={<Instructor />}> </Route>
            <Route path="/addReview/:id" element={<AddReviews />}> </Route>
            <Route path="/B2B" element={<B2B />}> </Route>
            <Route path="/studio" element={<Studio />}> </Route>
            <Route path="/coursedegree" element={<Coursedegree />}> </Route>
            <Route path="/details/:id" element={<CourseDetails />}> </Route>
            <Route path="/TermsAndCond" element={<TermsAndCond />}></Route>
            <Route path="/Policy" element={<PolicyAndPrivecy />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
            <Route path="/loading" element={<Loading />}></Route>
            <Route path="/partners" element={<Partners />}></Route>
            {/* <Route path="/coupon" element={<Coupon />}></Route> */}
            <Route path="*" element={<NotFoundPage />} /> </Routes>

        </div>

        {location.pathname !== '/loading' && <Footer />}
      </div>

    </>

  );
}

export default App;